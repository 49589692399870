.react-datepicker__input-container{
   width: 100%;
   height: 32px;
   padding: 0;
   display: block;
   flex: 1;
   border: none;
   background: none;
   transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
   transition-property: font-size;
   font-size: 16px;
   color: #303030;
   font-family: inherit;
   line-height: 32px;

   border-bottom: 1px solid #d3d3d3;

   
}

.react-datepicker__input-container::placeholder{
   font-size: 12px;
}

.react-datepicker__input-container span {
   color: #f64c75;
   align-self: flex-start;
   margin: 0 0 10 px;
   font-weight: bold;
}

.react-datepicker__input-container label{
  
   display: block;
    pointer-events: auto;
   top: 10px;

   opacity: 1;
   font-size: 12px;
   transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
   transition-property: opacity;
   
}

.react-datepicker__input-container label:focus-within {
   top: 0;
   opacity: 1;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
}