/* DEFAULT COLOR VARIABLES */
/* DEFAULT SIZING VARIABLES */
/* DEFAULT CLASS VARIABLE */
/* STYLES */
.switch.switch--default > .switch-toggle {
   height: 20px;
   width: 42px;
   cursor: pointer !important;
   user-select: none !important;
   position: relative !important;
   display: inline-block; }
   .switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::before, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
     content: '';
     left: 0;
     position: absolute !important; }
   .switch.switch--default > .switch-toggle.switch-toggle--on::before, .switch.switch--default > .switch-toggle.switch-toggle--off::before {
     height: inherit;
     width: inherit;
     border-radius: 10px;
     will-change: background;
     transition: background .4s .3s ease-out; }
   .switch.switch--default > .switch-toggle.switch-toggle--on::after, .switch.switch--default > .switch-toggle.switch-toggle--off::after {
     top: 2px;
     height: 16px;
     width: 16px;
     border-radius: 14px;
     background: #ffffff !important;
     will-change: transform;
     transition: transform .2s ease-out; }
   .switch.switch--default > .switch-toggle.switch-toggle--on::before {
     background: #0CE0FF !important; }
   .switch.switch--default > .switch-toggle.switch-toggle--on::after {
     transform: translateX(24px); }
   .switch.switch--default > .switch-toggle.switch-toggle--off::before {
     background: #cccccc !important; }
   .switch.switch--default > .switch-toggle.switch-toggle--off::after {
     transform: translateX(2px); }

 .switch.switch--graphite-small > .switch-toggle {
   height: 20px;
   width: 32.5px;
   cursor: pointer !important;
   user-select: none !important;
   position: relative !important;
   display: inline-block; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
     content: '';
     left: 0;
     position: absolute !important; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
     height: inherit;
     width: inherit;
     border-radius: 10px;
     will-change: background;
     transition: background .4s .3s ease-out; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after, .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
     top: 2px;
     height: 16px;
     width: 16px;
     border-radius: 8px;
     background: #ffffff !important;
     will-change: transform;
     transition: transform .2s ease-out; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::before {
     background: gray !important; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--on::after {
     transform: translateX(32px); }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::before {
     background: #cccccc !important; }
   .switch.switch--graphite-small > .switch-toggle.switch-toggle--off::after {
     transform: translateX(2px); }
